@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  width: 1145px;
}

@media (max-width: 600px) {
  .container {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .container {
    width: 85%;
  }
}

html {
  @apply scroll-smooth scroll-pt-32;
}

.tns-outer > button {
  display: none;
}
